<template>
  <v-dialog v-model="active" width="600" persistent>
    <template v-slot:activator="{ on:dialogOn }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on:tooltipOn }">
          <v-btn icon v-on="{ ...dialogOn, ...tooltipOn }">
            <v-icon>fal fa-toolbox</v-icon>
          </v-btn>
        </template>
        <span>Initial Chapel Setup for Term</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>Set up Term</v-card-title>
      <v-card-text>
        <v-select v-model="term" :items="termOptions" label="Term" outlined></v-select>
        <p>This action will create a chapel for every Monday, Wednesday, and Friday for the given term, starting and ending with the days listed in Banner for the start and end of the term. No speaker or title will be set on these by default.</p>
        <p>This will create {{ chapelCount }} chapels.</p>
      </v-card-text>
      <v-alert v-if="isSaving">Adding chapels...</v-alert>
      <v-card-actions v-else>
        <v-btn text @click="active = false">Cancel</v-btn>
        <v-btn :disabled="!isReady" text color="primary" @click="doSetup()">Create Chapels</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import { ref, computed, watch, onMounted } from '@vue/composition-api'

export default {
  setup (props, { root, emit }) {
    const active = ref(false)
    const term = ref('')
    const termOptions = ref([])
    const termAlreadySetUp = ref(false)
    const isReady = ref(false)
    const isSaving = ref(false)
    const chapelDates = ref([])

    watch(term, () => {
      if (term.value !== '') {
        chapelDates.value = []
        root.$feathers.service('system/term').find({ query: { term: term.value } }).then(({ data }) => {
          let { term, start, end, closures } = data[0]
          root.$feathers.service('chapel/schedule').find({ query: { term } }).then(async ({ total }) => {
            if (total > 0) {
              termAlreadySetUp.value = true
            } else {
              termAlreadySetUp.value = false
              let first = new Date(start)
              first.setHours(11)
              first.setMinutes(0)
              first.setSeconds(0)
              first.setMilliseconds(0)
              let last = new Date(end)
              last.setHours(11)
              last.setMinutes(0)
              last.setSeconds(0)
              last.setMilliseconds(0)
              let closureDates = {}
              for (let i = 0; i < closures.length; i++) {
                let closureStart = new Date(closures[i].start)
                let closureEnd = new Date(closures[i].end)
                while (closureStart.getTime() <= closureEnd.getTime()) {
                  closureDates[closureStart.toISOString().substr(0, 10)] = true
                  closureStart.setDate(closureStart.getDate() + 1)
                }
              }
              while (first.toISOString() < last.toISOString()) {
                if (first.getDay() % 2 === 1) {
                  // Check to see if this date exists in the closures array; only add if it does not exist in that array
                  if (!(first.toISOString().substr(0, 10) in closureDates)) chapelDates.value.push(new Date(first))
                }
                first.setDate(first.getDate() + 1)
              }
              isReady.value = true
            }
          })
        })
      }
    })
    const chapelCount = computed(() => { return chapelDates.value.length })
    async function doSetup () {
      let termDesc = ''
      termOptions.value.forEach(({ text, value }) => { if (value === term.value) termDesc = text })
      isSaving.value = true
      const service = root.$feathers.service('chapel/schedule')
      for (let i = 0; i < chapelDates.value.length; i++) {
        let date = chapelDates.value[i]
        await service.create({ term: term.value, termDesc, date })
      }
      isSaving.value = false
      alert('The chapels have been added to the schedule')
      for (let i = 0; i < termOptions.value.length; i++) {
        if (termOptions.value[i].value === term.value) {
          termOptions.value.splice(i, 1)
        }
      }
      emit('updated')
      active.value = false
    }
    onMounted(() => {
      let dt = new Date()
      dt.setFullYear(dt.getFullYear() + 1)
      root.$feathers.service('system/term').find({ query: { type: 'Traditional', end: { $gt: new Date() }, start: { $lte: dt } } }).then(async ({ data }) => {
        let arr = []
        for (let i = 0; i < data.length; i++) {
          let text = data[i].label
          let value = data[i].term
          let { total } = await root.$feathers.service('chapel/schedule').find({ query: { term: value, $limit: 0 } })
          if (total === 0) arr.push({ text, value })
        }
        termOptions.value = arr
      })
    })
    return { active, term, termOptions, termAlreadySetUp, isReady, isSaving, chapelDates, chapelCount, doSetup }
  }
}
</script>
